<template>
  <div class="vg_wrapper">
    <el-card v-loading="isLoding">
      <div class="vd_button_group">
        <span v-if="disabled === true">
          <el-button
            type="success"
            @click="openEdit"
            size="small"
            :disabled="!btn.edit"
            >编辑</el-button
          >
        </span>
        <span v-if="disabled === false">
          <el-button type="primary" @click="submit('stffForm')" size="mini"
            >保存</el-button
          >
          <el-button @click="closeEdit" size="mini">取消</el-button>
        </span>
      </div>
      <el-form
        ref="stffForm"
        :model="stffForm"
        :rules="rules"
        :disabled="disabled"
        label-width="120px"
        size="mini"
      >
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="员工编号" prop="stff_no" required>
              <el-input
                v-model="stffForm.stff_no"
                maxlength="10"
                show-word-limit
                placeholder="请填写员工编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="员工姓名" prop="stff_name" required>
              <el-input
                v-model="stffForm.stff_name"
                maxlength="10"
                show-word-limit
                placeholder="请填写员工姓名（中文）"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司抬头" prop="cptt_id" required>
              <el-select
                v-model="stffForm.cptt_id"
                placeholder="请选择公司抬头"
                clearable
                @visible-change="getAcctFctrs($event)"
                @change="selectCpttName"
              >
                <el-option
                  v-for="item in cpttGroupOpt"
                  :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="部门名称" prop="dept_id" required>
              <el-select
                v-model="stffForm.dept_id"
                placeholder="请选择部门"
                clearable
                @change="openTeam"
              >
                <el-option
                  v-for="item in deptGroupOpt"
                  :key="item.dept_id"
                  :label="item.dept_name"
                  :value="item.dept_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="小组名称" prop="dept_team_id" required>
              <el-select
                v-model="stffForm.dept_team_id"
                placeholder="请选择小组"
                clearable
                @visible-change="getTeamByDept($event)"
                :disabled="teamDisabled"
              >
                <el-option
                  v-for="item in teamGroupOpt"
                  :key="item.dept_team_id"
                  :label="item.dept_team_name"
                  :value="item.dept_team_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="员工岗位">
              <el-input
                v-model="stffForm.stff_job"
                maxlength="10"
                placeholder="请填写岗位"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="上级领导">
              <select-input
                @refreshTableData="refreshTableData"
                :selTableData="selTableData"
                :tableHeader="staffTableHeader"
                @getSelTableRow="getSelTableRow"
                :inputVal="stffForm.stff_leader_name"
                :disabled="disabled"
                :rowSelect="rowSelect"
                :isShowCol="false"
                placeholder="请选择上级领导"
              >
              </select-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="入职时间" prop="stff_enter_time" required>
              <el-date-picker
                type="date"
                placeholder="请选择入职时间"
                v-model="stffForm.stff_enter_time"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="离职时间" prop="stff_leave_time">
              <el-date-picker
                type="date"
                placeholder="请选择离职时间"
                v-model="stffForm.stff_leave_time"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="员工司龄" prop="stff_seniority">
              <el-input
                v-model="stffForm.stff_seniority"
                placeholder="根据入职或者离职时间自动填充"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="开户银行" prop="stff_bank_name">
              <el-input
                v-model="stffForm.stff_bank_name"
                maxlength="30"
                show-word-limit
                placeholder="请填写开户银行"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行卡号" prop="stff_bank_no">
              <el-input
                v-model="stffForm.stff_bank_no"
                maxlength="30"
                show-word-limit
                placeholder="请填写银行卡号"
              ></el-input>
            </el-form-item>
            <el-form-item label="身份证号">
              <el-input
                v-model="stffForm.stff_identity"
                maxlength="18"
                show-word-limit
                placeholder="请填写身份证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="员工性别">
              <el-input
                v-model="stffForm.stff_gender"
                placeholder="根据身份信息自动填充"
                disabled
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="聘用类型">
              <el-input
                v-model="stffForm.stff_labr_type"
                placeholder="请填写聘用类型"
                 maxlength="30"
                show-word-limit
              ></el-input>
            </el-form-item>
            <el-form-item label="员工电话">
              <el-input
                v-model="stffForm.stff_phone"
                maxlength="30"
                show-word-limit
                placeholder="请填写电话"
                @input="
                  stffForm.stff_phone = helper.purephone(stffForm.stff_phone)
                "
              ></el-input>
            </el-form-item>
            <el-form-item label="当前住址">
              <el-input
                v-model="stffForm.stff_address"
                maxlength="30"
                show-word-limit
                placeholder="请填写员工现住址"
              ></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                type="textarea"
                :rows="2"
                v-model="stffForm.stff_remark"
                maxlength="100"
                show-word-limit
                placeholder="请填写备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="vg_mt_16">
          <el-col>
            <inputUser
              :isCookie="true"
              ref="userData"
              v-if="stffForm.stff_name"
            ></inputUser>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { get, post } from "@api/request";
import { stffAPI } from "@api/modules/staff";
import { acctAPI } from "@api/modules/acct";
import { deptAPI } from "@api/modules/department";
import { staffTableHeader } from "@/views/component/tableHeader/staffTableHeader";
import { cpttAPI} from '@api/modules/comptitle'
import selectInput from "@/views/component/selectInput";
import options from "@/views/component/common/options";
import inputUser from "@/views/component/inputUser";
import { imgeAPI } from "@api/modules/imge";
import helper from "@assets/js/helper";
import { optnAPI } from "@api/modules/optn";
export default {
  name: "StffAdd.vue",
  components: {
    selectInput,
    inputUser,
  },
  data() {
    return {
      stffForm: {
        stff_id: null,
        stff_ext_id: null,
        stff_no: "",
        stff_name: "",
        stff_leader: "",
        stff_leader_name: "",
        acct_fctr_id: null,
        dept_id: null,
        dept_team_id: null,
        stff_enter_time: null,
        stff_leave_time: null,
        stff_gender: "",
        stff_phone: "",
        stff_identity: "",
        stff_address: "",
        stff_job: "",
        stff_bank_no: "",
        stff_handbook: "",
        stff_remark: "",
        stff_hire_tap: "",
        stff_labr_list: [],
        cptt_id:''
      },
      key: 0,
      compTitle: "",
      btn: {},
      disabled: true,
      teamDisabled: true,
      merryDisabled: false,
      cpttGroupOpt: [],
      deptGroupOpt: [],
      teamGroupOpt: [],
      contGroupOpt: [], // 合同类型
      selectionsList: [],
      selTableData: [],
      staffTableHeader: [],
      pickerOptions: this.beginDate(),
      rules: {},
      rowSelect: [],
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        cut: true,
        x: 200,
        y: 200,
        imge_id: 0,
      },
      copyForm: [],
      isLoding: true,
    };
  },
  watch: {
    "stffForm.dept_id": function (newVal, oldVal) {
      if (oldVal) {
        if (newVal !== oldVal) {
          this.stffForm.dept_team_id = null;
          this.teamGroupOpt = [];
        }
      }
    },
    "stffForm.stff_marry": function (newVal) {
      if (newVal === "未婚未育" || newVal === "已婚未育") {
        this.merryDisabled = true;
      } else {
        this.merryDisabled = false;
      }
    },
    stffForm: {
      deep: true,
      handler(val) {
        if (val.stff_enter_time) {
          if (val.stff_leave_time) {
            this.stffForm.stff_seniority = this.helper.toSeniority(
              val.stff_enter_time / 1000,
              val.stff_leave_time / 1000
            );
          } else {
            this.stffForm.stff_seniority = this.helper.toSeniority(
              val.stff_enter_time / 1000,
              0
            );
          }
        } else {
          this.stffForm.stff_seniority = null;
        }
        if (val.stff_identity) {
          this.stffForm.stff_age = this.helper.mathUserInfo(
            val.stff_identity,
            3
          );
          this.stffForm.stff_birthday = this.helper.mathUserInfo(
            val.stff_identity,
            1
          );
          this.stffForm.stff_gender = this.helper.mathUserInfo(
            val.stff_identity,
            2
          );
        }
      },
    },
    "stffForm.dept_team_id": function (newVal) {
      if (newVal === 0) {
        this.stffForm.dept_team_id = null;
      }
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.$route.query.form_id) {
        this.getStffInfo();
        this.getAcctFctrs();
        this.getDeptV1();
        this.getSelectTable();
        this.staffTableHeader = staffTableHeader;
        this.contGroupOpt = options.labOpts;
      }
    },
    // 离职时间控制关闭选项
    beginDate() {
      let that = this;
      return {
        disabledDate(time) {
          return time.getTime() < that.stffForm.stffEnterTime;
        },
      };
    },
    // 刷新数据
    refreshTableData(){
      this.getSelectTable()
    },
    // 获取传递selTableData
    getSelectTable() {
      get(stffAPI.getAllStffsV1)
        .then((res) => {
          if (res.data.code === 0) {
            this.selTableData = res.data.data;
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取双击selTableData的row
    getSelTableRow(val) {
      this.stffForm.stff_leader = val.stff_id;
      this.stffForm.stff_leader_name = val.stff_name;
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (!this.stffForm.imge_id) {
        this.stffForm.imge_id = res.data.imge_id;
      }

      this.stffForm.imge_url = this.helper.picUrl(res.data.imge_url, "l");
    },
    //启用编辑
    openEdit() {
      this.disabled = false;
      this.teamDisabled = false;
    },
    //取消编辑
    closeEdit() {
      this.$confirm("是否撤销编辑?", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          this.disabled = true;
          this.teamDisabled = true;
          this.initData();
          this.$message({
            type: "info",
            message: "已撤销编辑!",
          });
        })
        .catch(() => {});
    },

    //提交表单
    submit(formName) {
      this.$confirm("确定提交？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log("error submit!");
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      this.stffForm.stff_id = this.$route.query.form_id;
      const stffFormBody = this.stffForm;
      stffFormBody.stff_enter_time = Number(
        new Date(this.stffForm.stff_enter_time).getTime() / 1000
      );
      stffFormBody.stff_leave_time = Number(
        new Date(this.stffForm.stff_leave_time).getTime() / 1000
      );
      let stffFormList = this.stffForm.stff_labr_list;
      let delete_time = this.helper.toTimeVal();
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        if (stffFormList.indexOf(this.copyForm[i]) !== -1) {
          this.copyForm.splice(i, 1);
        }
      }
      for (let i = this.copyForm.length - 1; i >= 0; i--) {
        this.copyForm[i].delete_time = delete_time;
      }
      stffFormList = stffFormList.concat(this.copyForm);
      for (let i = 0; i < stffFormList.length; i++) {
        stffFormList[i].stff_labr_in_time = Number(
          new Date(stffFormList[i].stff_labr_in_time).getTime() / 1000
        );
        stffFormList[i].stff_labr_out_time = Number(
          new Date(stffFormList[i].stff_labr_out_time).getTime() / 1000
        );
      }
      this.isLoding = true;
      stffFormBody.stff_labr_list = stffFormList;
      post(stffAPI.editStff, stffFormBody)
        .then((res) => {
          if (res.data.code === 0) {
            this.$message({
              type: "success",
              message: "保存成功",
            });
            this.disabled = true;
            this.teamDisabled = true;
            this.initData();
          } else if (res.data.code === 7) {
            this.$message({
              type: "error",
              message: "员工编号重复",
            });
            this.stffForm.stff_enter_time = Number(
              new Date(this.stffForm.stff_enter_time).getTime() * 1000
            );
            this.stffForm.stff_leave_time = Number(
              new Date(this.stffForm.stff_leave_time).getTime() * 1000
            );
            for (let i = 0; i < stffFormList.length; i++) {
              stffFormList[i].stff_labr_in_time = Number(
                new Date(stffFormList[i].stff_labr_in_time).getTime() * 1000
              );
              stffFormList[i].stff_labr_out_time = Number(
                new Date(stffFormList[i].stff_labr_out_time).getTime() * 1000
              );
            }
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
            this.stffForm.stff_enter_time = Number(
              new Date(this.stffForm.stff_enter_time).getTime() * 1000
            );
            this.stffForm.stff_leave_time = Number(
              new Date(this.stffForm.stff_leave_time).getTime() * 1000
            );
            for (let i = 0; i < stffFormList.length; i++) {
              stffFormList[i].stff_labr_in_time = Number(
                new Date(stffFormList[i].stff_labr_in_time).getTime() * 1000
              );
              stffFormList[i].stff_labr_out_time = Number(
                new Date(stffFormList[i].stff_labr_out_time).getTime() * 1000
              );
            }
            this.initData();
          }
          setTimeout(() => [(this.isLoding = false)], 500);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    //添加
    addRow() {
      let item = {
        stff_labr_cont: null,
        stff_labr_in_time: null,
        stff_labr_out_time: null,
        acct_fctr_name: this.compTitle,
        key: this.key,
      };
      this.key = this.key + 1;
      this.stffForm.stff_labr_list.push(item);
      this.selectionsList = [];
    },
    //
    handleSelectionChange(selection) {
      this.selectionsList = [];
      this.selectionsList = selection;
    },
    //多选删除
    delLabr() {
      this.stffForm.stff_labr_list = this.stffForm.stff_labr_list.filter(
        (item) =>
          this.selectionsList.every(
            (item1) => item.stff_labr_id != item1.stff_labr_id
          )
      );
    },
    // 获取cpttId
    getAcctFctrs() {
       get(cpttAPI.get_my_cptts_v1)
        .then((res) => {
          if (res.data.code === 0) {
            this.cpttGroupOpt = res.data.data;
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取deptId
    getDeptV1() {
      get(deptAPI.getAllDeptsV1)
        .then((res) => {
          if (res.data.code === 0) {
            this.deptGroupOpt = res.data.data;
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取deptTeamId
    getTeamByDept() {
      if (this.teamGroupOpt.length === 0) {
        get(deptAPI.getTeamsByDeptId, { dept_id: this.stffForm.dept_id })
          .then((res) => {
            if (res.data.code === 0) {
              this.teamGroupOpt = res.data.data;
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch((res) => {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          });
      }
    },
    // 下拉获取公司抬头的name
    selectCpttName(params) {
      let cpttObj = this.cpttGroupOpt.find((item) => {
        return item.cptt_id === params;
      });
      this.compTitle = cpttObj.cptt_name;
    },
    //开启选择小组
    openTeam(val) {
      if (val) {
        this.stffForm.dept_id = val;
        this.teamDisabled = false;
      } else {
        this.teamDisabled = true;
      }
    },
    //获取表单信息
    getStffInfo() {
      this.stffForm.stff_id = this.$route.query.form_id;
      get(stffAPI.getStffById, { stff_id: this.stffForm.stff_id })
        .then((res) => {
          if (res.data.code === 0) {
            setTimeout(() => {
              this.isLoding = false;
            }, 500);
            this.stffForm = res.data.data.form;
            setTimeout(() => {
              if (this.stffForm.stff_leave_time === 0) {
                this.stffForm.stff_leave_time = "";
              }
            }, 500);
            this.compTitle = this.stffForm.acct_fctr_name;
            this.btn = res.data.data.btn;
            this.getTeamByDept();
            if(this.stffForm.stff_enter_time){
              this.stffForm.stff_enter_time = this.stffForm.stff_enter_time * 1000;
            }else{
              this.stffForm.stff_enter_time = null
            }
            if(this.stffForm.stff_leave_time){
              this.stffForm.stff_leave_time = this.stffForm.stff_leave_time * 1000;
            }else{
              this.stffForm.stff_leave_time = null
            }
            this.stffForm.stff_labr_list.forEach((item) => {
              item.stff_labr_in_time = item.stff_labr_in_time * 1000;
              item.stff_labr_out_time = item.stff_labr_out_time * 1000;
              item.acct_fctr_name = this.compTitle;
              item.k = this.key;
              this.key++;
            });
            this.copyForm = Object.assign(
              this.copyForm,
              this.stffForm.stff_labr_list
            );
          } else {
            let mg = res.data.msg;
            let tp = "error";
            this.$message({ message: mg, type: tp });
          }
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.vd_button {
  margin-top: 32px;
  border-top: 1px dashed $color-secondary-text;
  padding-top: 16px;
}
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}
.vd_title {
  color: $color-deep-red;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
</style>